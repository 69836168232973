import { Link } from "gatsby";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./style";
import styled from "styled-components";

import useStoryblok from "../../lib/storyblok";
import { useStaticQuery, graphql } from "gatsby";
import app_store from "~assets/custom/app_store.svg";
import play_store from "~assets/custom/play_store.svg";

const StyledDiv = styled.div`
  display: flex;
  img {
    height: 50px;
    padding-right: 15px;
  }

  width: 100%;

  @media (max-width: 400px) {
    justify-content: center;

    img {
      height: 45px;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
`;

export default function FooterSix({}) {
  const data = useStaticQuery(graphql`
    {
      storyblokEntry(full_slug: { eq: "footer" }) {
        content
        name
      }
    }
  `);

  let story = data.storyblokEntry;

  story = useStoryblok(story);

  var ios = "https://apps.apple.com/app/id1592819757";

  if (story?.ios?.url) {
    ios = story.link.url;
  }

  var android = "https://play.google.com/store/apps/details?id=com.careaid.mobileapp";

  if (story?.android?.url) {
    android = story.link.url;
  }

  return (
    <Footer bg={story.content.background}>
      <Container>
        <Footer.Box pbXL="95px">
          <Row>
            <Footer.Box mb="30px">
              <Link to="/">
                <img
                  src={story.content.logo.filename}
                  alt={story.content.logo.alt}
                  height={`${story.content.logo_height}px`}
                />
              </Link>
            </Footer.Box>
          </Row>
          <Row>
            <Col xs="12" className="col-lg-4 col-md-7 col-xs-9 app-hide">
              <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Text
                  mb="36px"
                  dangerouslySetInnerHTML={{ __html: story.content.copy_left }}
                  color={story.content.text_color}
                  pr="50px"
                ></Footer.Text>
                <StyledDiv>
                  <a href={ios} target="_blank">
                    <img src={app_store}></img>
                  </a>
                  <a href={android} target="_blank">
                    <img src={play_store}></img>
                  </a>
                </StyledDiv>
              </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-lg-8">
              <Row>
                <Col xs="6" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.List>
                      {story.content.link_block_1.map((item, index) => {
                        var url = "";
                        if (item.link.cached_url == "") {
                          url = item.link.url;
                        } else {
                          url = item.link.cached_url;
                        }

                        return (
                          <Footer.ListItems color={story.content.text_color}>
                            <Link to={`${url}`}>{item.title}</Link>
                          </Footer.ListItems>
                        );
                      })}
                    </Footer.List>
                  </Footer.Widgets>
                </Col>
                <Col xs="6" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.List>
                      {story.content.link_block_2.map((item, index) => {
                        var url = "";
                        if (item.link.cached_url == "") {
                          url = item.link.url;
                        } else {
                          url = item.link.cached_url;
                        }
                        return (
                          <Footer.ListItems color={story.content.text_color}>
                            <Link to={`${url}`}>{item.title}</Link>
                          </Footer.ListItems>
                        );
                      })}
                    </Footer.List>
                  </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-md-4 col-xs-9">
                  <Footer.Widgets>
                    <Footer.Text
                      mb="36px"
                      dangerouslySetInnerHTML={{
                        __html: story.content.contact_info,
                      }}
                      color={story.content.text_color}
                    ></Footer.Text>
                  </Footer.Widgets>
                </Col>
              </Row>
            </Col>
          </Row>
        </Footer.Box>
        <Footer.Copyright>
          <Footer.CopyrightText color={story.content.text_color}>
            © 2022 Care Aid. All Rights Reserved.{" "}
            <a
              href="https://tensq.com.au"
              target="_blank"
              css={{ color: story.content.text_color }}
            >
              Powered By Ten Squared.
            </a>
          </Footer.CopyrightText>
        </Footer.Copyright>
      </Container>
    </Footer>
  );
}
